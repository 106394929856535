/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-08-01",
    versionChannel: "nightly",
    buildDate: "2024-08-01T00:06:16.945Z",
    commitHash: "70c6a1d629e675dfb6a135ca2c42d7e6564eb0b0",
};
